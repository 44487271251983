import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { globalHistory } from '@reach/router';
import { navigate } from 'gatsby';
import qs from 'qs';
import { Configure, InstantSearch, SearchBox } from 'react-instantsearch-dom';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import SVGs from '../assets/svg-icons/all.svg';
import { Container } from '../components/layout/container';
import { Footer } from '../components/layout/footer';
import { searchClient, algoliaIndexName } from '../components/search/search-client';
import { FilteredSearchResults } from '../components/search/filtered-search-results';
import { Header as SearchHeader } from '../components/search/header';
import { Header as MobileHeader } from '../components/layout/header';
import { SearchIcon } from '../components/search/search-icon';
import { SearchResultsFilter } from '../components/search/search-results-filter';
import { EmptyResults } from '../components/search/empty-results';
import config from '../../config/webConfig';

let redirects: Array<string> = [];

if (config.WEB_REDIRECTS) {
  redirects = config.WEB_REDIRECTS.split(',');
}

const DEBOUNCE_TIME = 1200;

const createURL = (state: SearchState) => `?${qs.stringify(state)}`;

const searchStateToUrl = (location: Location, searchState: SearchState) =>
  searchState ? `${location.pathname}${createURL(searchState)}` : '';

const urlToSearchState = (location: Location): SearchState =>
  (qs.parse(location.search.slice(1)) as unknown) as SearchState;

export default function SearchPage(): JSX.Element {
  const location = globalHistory?.location as Location;

  const [searchState, setSearchState] = useState<SearchState>(urlToSearchState(location));

  useEffect(() => {
    const nextSearchState = urlToSearchState(location);
    setSearchState(nextSearchState);
  }, [location]);

  const setStateId = React.useRef<NodeJS.Timeout>();

  const breakpoints = useBreakpoint();
  const Header = breakpoints.md ? MobileHeader : SearchHeader;
  const showMobileSearchbox = breakpoints.md;

  function onSearchStateChange(nextSearchState: SearchState) {
    clearTimeout(setStateId.current as NodeJS.Timeout);

    const setHistory = () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { configure, ...params } = nextSearchState;
      navigate(searchStateToUrl(location, params), { state: params });
    };

    if (nextSearchState.query !== searchState.query) {
      setStateId.current = setTimeout(setHistory, DEBOUNCE_TIME);
    } else {
      setHistory();
    }

    setSearchState(nextSearchState);
  }

  return (
    <div className="search-page bg-blue-000">
      <Helmet>
        <title>Search</title>
      </Helmet>
      <SVGs aria-hidden="true" />
      <InstantSearch
        searchClient={searchClient}
        indexName={algoliaIndexName}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
      >
        <Header redirects={redirects} />
        <Configure hitsPerPage={200} />
        {showMobileSearchbox && (
          <div className="ais-SearchBox-Mobile-Container px-6">
            <SearchBox searchAsYouType={true} autoFocus={true} submit={<SearchIcon />} />
          </div>
        )}
        <EmptyResults />
        <Container className="flex">
          {!showMobileSearchbox && (
            <div className="ais-SearchResult-Filter-Container">
              <SearchResultsFilter />
            </div>
          )}
          <FilteredSearchResults />
        </Container>
      </InstantSearch>
      <Footer redirects={redirects} />
    </div>
  );
}
