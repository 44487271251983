import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { createConnector, SearchBox } from 'react-instantsearch-dom';

import { SearchIcon } from './search-icon';
import { Container } from '../layout/container';
import { NavLogoQueryQuery } from '../../../../../graphql-types';

const Empty = () => <div />;

const connector = createConnector({
  displayName: 'ResetSearchBox',
  refine(props, searchState, menuFilter) {
    // When the underlying component calls its `refine` prop,
    // we update the searchState with the provided refinement.
    return {
      // `searchState` represents the search state of *all* widgets. We need to extend it
      // instead of replacing it, otherwise other widgets will lose their respective state.
      ...searchState,
      menuFilter,
      page: 1,
    };
  },
  getProvidedProps(props, searchState) {
    return { searchState };
  },
});

export const Header = connector(
  (): JSX.Element => {
    const { nav } = useStaticQuery<NavLogoQueryQuery>(graphql`
      query NavLogoQuery {
        nav: datoCmsLearnHeader {
          navLinks {
            text
            url
          }
          lightLogo {
            url
          }
          darkLogo {
            url
          }
        }
      }
    `);

    const lightLogo = nav?.lightLogo?.url || undefined;

    return (
      <header className="ais-Searchpage-Header z-50 w-full bg-gray-00">
        <Container>
          <div className="flex items-center justify-between h-16 lg:h-auto">
            <a href="/" className="flex items-center">
              <img className="logo block h-8" src={lightLogo} alt="Mattr. " />
              <span className="header-learn flex items-center">Learn</span>
            </a>
            <div className="md:my-10 lg:flex ml-20 w-full">
              <SearchIcon />
              <SearchBox searchAsYouType={true} autoFocus={true} submit={<Empty />} />
            </div>
          </div>
        </Container>
      </header>
    );
  },
);
