import React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { hitsPerPage } from './constants';
import { filterConnector } from './filter-connector';
import { ResultsPaging } from './results-paging';
import { SearchResult } from './search-result';

export const FilteredSearchResults = filterConnector(
  ({ hits, refine, page = 1, query, totalResults, filterName }): JSX.Element => {
    const breakpoints = useBreakpoint();
    const showFiltername = breakpoints.md && !!filterName;

    if (typeof page === 'string') {
      page = parseInt(page);
    }

    const high = page * hitsPerPage;
    const low = high - hitsPerPage + 1;

    return (
      <div className="ais-Hit-Container">
        <div className="ais-Filtered-Hits">
          {totalResults > 0 && (
            <>
              <div className="mt-2 md:mt-6 md:ml-4">
                Showing&nbsp;
                <strong>
                  {low} - {Math.min(high, totalResults)}
                </strong>
                &nbsp;out of <strong>{totalResults}</strong> for {showFiltername && <span> {filterName}: </span>}
                {query}
              </div>
              {showFiltername && (
                <div
                  className="leading-10 cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    refine({ menuFilter: 'All' });
                  }}
                >
                  Show all results
                </div>
              )}
            </>
          )}
          <ul className="ais-Hits-list">
            {hits.map((hit) => (
              <li className="ais-Hits-item pt-4 md:pt-0" key={hit.objectID}>
                <SearchResult hit={hit} refine={refine} />
              </li>
            ))}
          </ul>
        </div>
        <ResultsPaging
          onPageChange={() => {
            window.scrollTo(0, 0);
          }}
        />
      </div>
    );
  },
);
