import React from 'react';
import cx from 'classnames';
import { createConnector } from 'react-instantsearch-dom';

import { SiteNames } from './constants';
import { getFriendlyCategoryName, getSubCategoryName } from './utils';

/**
 * Generate menu items from hits.
 * @param hits
 */
const getMenuItemsForHits = (hits: Array<Hit>) => {
  const categories = new Map<string, FilterMenuCategory>();
  hits.forEach((h) => {
    const topCategory = h.hierarchicalCategories.lvl0;
    const subCategory = h.hierarchicalCategories.lvl1;

    let item: FilterMenuCategory;
    if (!categories.has(topCategory)) {
      item = {
        count: 1,
        name: getFriendlyCategoryName(topCategory),
        refinement: topCategory,
        subItems: new Map(),
      };
      categories.set(topCategory, item);
    } else {
      item = categories.get(topCategory) as FilterMenuCategory;
      item.count = item.count + 1;
    }

    if (SiteNames[item.name].subcategories && subCategory) {
      const name = getSubCategoryName(subCategory);
      let subItem: FilterMenuItem;
      if (item.subItems.has(name)) {
        subItem = item.subItems.get(name) as FilterMenuItem;
        subItem.count = subItem.count + 1;
      } else {
        subItem = {
          count: 1,
          name,
          refinement: subCategory,
        };
        item.subItems.set(name, subItem);
      }
    }
  });

  return categories;
};

const connectWithQuery = createConnector({
  displayName: 'SearchResultsFilter',
  getProvidedProps(props, searchState, searchResults) {
    const currentRefinement = searchState.menuFilter || 'All';

    const hits = searchResults?.results?.hits || [];
    let categories = new Map<string, FilterMenuCategory>();
    const sites = Object.keys(SiteNames);

    // Scan through result in the order specified by the SiteNames constant. This keeps the menu order consistent.
    sites.forEach((site) => {
      const siteHits = hits.filter((hit) => getFriendlyCategoryName(hit.hierarchicalCategories.lvl0) === site);
      const siteCategories = getMenuItemsForHits(siteHits);
      categories = new Map([...categories, ...siteCategories]);
    });

    // Props received by the component wrapped by this HOC
    return { currentRefinement, categories };
  },
  refine(props, searchState, menuFilter) {
    // When the underlying component calls refine, update the searchState with the provided refinement.
    return {
      // searchState represents the search state of all widgets. Extend it instead of replacing it, otherwise other
      // widgets will lose their respective state.
      ...searchState,
      menuFilter,
      page: 1,
    };
  },
  cleanUp(props, searchState) {
    // When the widget is unmounted, omit the entry `menuFilter` from searchState, so that on the next request the
    // filter will be empty
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { menuFilter, ...nextSearchState } = searchState;

    return nextSearchState;
  },
});

/**
 * Menu of categories based on search results. Allows filtering of results based on category.
 */
export const SearchResultsFilter = connectWithQuery(({ currentRefinement, refine, categories }) => {
  if (!categories || categories.size === 0) {
    return null;
  }

  const isAllSelected = currentRefinement === 'All';

  return (
    <>
      <div
        className={cx('cursor-pointer mt-1.5 w-40', { 'text-blue-500': isAllSelected })}
        tabIndex={-1}
        onClick={() => refine('All')}
      >
        All Results
      </div>
      {[...categories].map(([, item]) => {
        const isSelected = currentRefinement === item.refinement;
        const subItems = [...item.subItems];

        return (
          <React.Fragment key={item.name}>
            <div className="flex flex-row justify-between items-end">
              <div
                className={cx('cursor-pointer mt-3 font-semibold ', { 'text-blue-500': isSelected })}
                tabIndex={-1}
                onClick={() => refine(item.refinement)}
              >
                {item.name}
              </div>
              <div>{item.count}</div>
            </div>
            {subItems.map(([, subItem]) => {
              const isSelected = currentRefinement === subItem.refinement;

              return (
                <div key={subItem.name} className="flex flex-row justify-between items-end">
                  <div
                    className={cx('cursor-pointer mt-1.5 ml-3 whitespace-nowrap overflow-hidden overflow-ellipsis', {
                      'text-blue-500': isSelected,
                    })}
                    tabIndex={-1}
                    onClick={(e) => {
                      refine(subItem.refinement);
                      e.stopPropagation();
                    }}
                  >
                    {subItem.name}
                  </div>
                  <div className="w-12" />
                  <div>{subItem.count}</div>
                </div>
              );
            })}
          </React.Fragment>
        );
      })}
    </>
  );
});
