import React from 'react';
import { Highlight } from 'react-instantsearch-dom';

import { SiteNames } from './constants';
import { categoryToPillname, getFriendlyCategoryName, getSubCategoryName } from './utils';

export const SearchResult = ({ hit, refine }: HitProps): JSX.Element => {
  const topCategory = hit.hierarchicalCategories.lvl0;
  const subCategory = hit.hierarchicalCategories.lvl1;

  const categoryName = categoryToPillname(topCategory);
  const topRefinement = { menuFilter: topCategory, page: 1 };
  const subRefinement = { menuFilter: subCategory as string, page: 1 };
  const hasSubCategory = SiteNames[getFriendlyCategoryName(topCategory)].subcategories;

  return (
    <div className="ais-SearchResult hover:bg-white">
      <a href={hit.url} className="ais-SearchResult-Link">
        <div>
          <div className="ais-Hit-Title">
            <Highlight hit={hit} attribute="title" tagName="mark" />
          </div>
          <div className="ais-Hit-URL">
            <span>{hit.url}</span>
          </div>
          <div className="ais-Hit-Excerpt mt-2 mb-4 md:mt-4">
            <Highlight hit={hit} attribute="text" tagName="mark" />
          </div>
        </div>
      </a>
      <div>
        <div className="cursor-pointer ais-Filtered-Hit-TopCategory" onClick={() => refine(topRefinement)}>
          {categoryName}
        </div>
        {hasSubCategory && subCategory && (
          <div className="cursor-pointer ais-Filtered-Hit-SubCategory ml-3" onClick={() => refine(subRefinement)}>
            {getSubCategoryName(subCategory).toUpperCase()}
          </div>
        )}
      </div>
    </div>
  );
};
