import React from 'react';
import { Link } from '@mattr/shared-components';

import { Container } from '../layout/container';
import { filterConnector } from './filter-connector';

export const EmptyResults = filterConnector(({ hits, query, searching }): JSX.Element | null => {
  if (hits.length > 0) {
    return null;
  }

  const noResultsFound = !searching && query && query.length > 2 && !hits.length;
  const emptySearch = !searching && (!query || query.length < 3);

  return (
    <div className="ais-EmptyResults-Container">
      <>
        {searching && (
          <div className="m-auto p-4 text-center">
            <i>searching...</i>
          </div>
        )}
        {noResultsFound && (
          <Container className="pt-14 pb-16">
            <div className="p-4">
              <h1 className="font-semibold text-3xl">No results found...</h1>
              <p>
                Our search engine could not find the information you&rsquo;re after. But that doesn&rsquo;t mean the
                content you&rsquo;re after doesn&rsquo;t exist...
              </p>

              <p>
                Roughly 85% of our universe is made up of{' '}
                <Link
                  className="underline hover:no-underline"
                  to="https://home.cern/science/physics/dark-matter"
                  attrs={{ target: '_blank' }}
                >
                  dark matter↗
                </Link>{' '}
                &ndash; it doesn&rsquo;t absorb, reflect or emit light, making it very hard to find. That being said,
                astrophysics is a bit out of scope with what we do here at MATTR. If you&rsquo;re wanting to learn more
                about a new world of data and digital trust, here are some starting points:
              </p>
              <p>
                <ul>
                  <li>
                    <Link to="https://mattr.global/" title="MATTR homepage" attrs={{ target: '_blank' }}>
                      - <span className="underline hover:no-underline">MATTR homepage ↗</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/" title="MATTR Learn">
                      - <span className="underline hover:no-underline">MATTR Learn</span>
                    </Link>
                  </li>
                </ul>
              </p>
              <p>
                Hopefully you would be able to find what you were looking for elsewhere on our sites, otherwise
                please&nbsp;
                <Link
                  to="https://mattr.global/contact/"
                  attrs={{ target: '_blank' }}
                  className="underline hover:no-underline"
                >
                  <span>contact us↗</span>
                </Link>
                &nbsp;and we’d be more than happy to help.
              </p>
            </div>
          </Container>
        )}
        {emptySearch && (
          <Container className="text-center mt-8">Search results will appear here as you type.</Container>
        )}
      </>
    </div>
  );
});
